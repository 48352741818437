import "./notification_web_form.css";

import ViewController from "../../commons/base/view_controller";
import Scaffold from "../../commons/scaffold/scaffold";
import SizedDiv from "../../commons/ui/sized_div";
import NotificationWebFormState from "../view_models/notification_web_form_state";
import NotificationWebFormViewModel from "../view_models/notification_web_form_view_model";
import NotificationWebFormFormWidget from "../widgets/notification_web_form_form_widget";
import NotificationWebFormResultWidget from "../widgets/notification_web_form_result_widget";

export default class NotificationWebFormScreen extends ViewController<{}, NotificationWebFormState, NotificationWebFormViewModel> {
    constructor(props: {}) {
        super(props, new NotificationWebFormViewModel());
    }

    render() {
        return <Scaffold
            isShowLoading={this.vmState.isLoading}
            showSnackbar={this.vmState.errorSnackbar}
            loadingBackgroundType="semi_transparent"
            loadingType="uena_logo"
        >
            <div id="NotificationWebFormScreenContainer">
                <div id="NotifactionWebFormScrollableContainer">
                    <p style={{
                        fontSize:32,
                        fontWeight:700,
                        textAlign:"center"
                    }}>Notification Web Form</p>
                    <SizedDiv height={42} />
                    {
                        this.vmState.webViewLink ?
                        <NotificationWebFormResultWidget vc={this} /> :
                        <NotificationWebFormFormWidget vc={this} />
                    }
                </div>
                {
                    !this.vmState.webViewLink && 
                    <div id="AdhocBottomSection">
                        <button 
                            className="FormButton"
                            onClick={() => this.viewModel.submit()}
                        >Generate Web View</button>
                    </div>
                }
            </div>
        </Scaffold>;
    }
}