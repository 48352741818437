 import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdhocCrmScreen from "../adhoc_crm/adhoc_crm_screen";
import CreateOrderScreen from "../create_order/cs/create_order_screen";
import HomeScreen from "../home/home_screen";
import ServiceAreasMapScreen from "../service_areas_map/service_areas_map_screen";
import NotificationWebFormScreen from "../notification_web/screens/notification_web_form_screen";
import NotificationWebDisplayScreen from "../notification_web/screens/notification_web_display_screen";
import WebRedirectFormScreen from "../redirect/screens/web_redirect_form_screen";
import WebRedirectDisplayScreen from "../redirect/screens/web_redirect_display_screen";
import CrmHomeScreen from "../home/crm_home_screen";
import MobileQrisScreen from "../mobile_app/qris/mobile_qris_screen";
import DownloadAppScreen from "../download_app/download_app_screen";
import CashierOrderScreen from "../create_order/cashier/cashier_order_screen";
import UenaWebsiteScreen from "../website/uena_website_screen";
import TncScreen from "../mobile_app/tnc/tnc_screen";
import ReferralScreen from "../mobile_app/referral/referral_screen";
import DriverSignInScreen from "../driver/sign_in/driver_sign_in_screen";
import DriverRootScreen from "../driver/root/driver_root_screen";
import DriverOrderListScreen from "../driver/order_list/driver_order_list_screen";
import DriverOrderDetailScreen from "../driver/order_detail/driver_order_detail_screen";
import GerobakOrderScreen from "../create_order/gerobak/gerobak_order_screen";

export class UenaRouter extends React.Component {
    render() {
        return(
            <Router>
                <div className="App">
                    <Routes>
                        {
                            (process.env.REACT_APP_ENV === "staging" || process.env.REACT_APP_ENV === "production") &&
                            <>
                                <Route path="/homepage" element={<UenaWebsiteScreen />} />
                                <Route path="/adhoc-crm" element={<AdhocCrmScreen />} />
                                <Route path="/crm" element={<CrmHomeScreen />} />
                                <Route path="/create/order" element={<CreateOrderScreen />} />
                                <Route path="/create/order-cashier" element={<CashierOrderScreen />} />
                                <Route path="/download" element={<DownloadAppScreen />} />
                                <Route path="/notif" element={<NotificationWebDisplayScreen />} />
                                <Route path="/notification-web-form" element={<NotificationWebFormScreen />}/>
                                <Route path="/qris" element={<MobileQrisScreen />} />
                                <Route path="/redirect" element={<WebRedirectDisplayScreen />} />
                                <Route path="/redirect-form" element={<WebRedirectFormScreen />} />
                                <Route path="/referral-detail" element={<ReferralScreen />} />
                                <Route path="/service-area" element={<ServiceAreasMapScreen />} />
                                <Route path="/" element={<HomeScreen />} />
                                <Route path="/*" element={<HomeScreen />} />
                            </>
                        }
                        {
                            (process.env.REACT_APP_ENV === "driver" || process.env.REACT_APP_ENV === "driver-staging") &&
                            <>
                                <Route path="/order_detail" element={<DriverOrderDetailScreen />} />
                                <Route path="/order_list" element={<DriverOrderListScreen />} />
                                <Route path="/sign_in" element={<DriverSignInScreen />} />
                                <Route path="/" element={<DriverRootScreen />} />
                                <Route path="/*" element={<DriverRootScreen />} />
                            </>
                        }
                        {
                            process.env.REACT_APP_ENV === "landing" &&
                            <>
                                <Route path="/download" element={<DownloadAppScreen />} />
                                <Route path="/notif" element={<NotificationWebDisplayScreen />} />
                                <Route path="/qris" element={<MobileQrisScreen />} />
                                <Route path="/redirect" element={<WebRedirectDisplayScreen />} />
                                <Route path="/referral-detail" element={<ReferralScreen />} />
                                <Route path="/terms-and-condition" element={<TncScreen />} />
                                <Route path="/tnc" element={<TncScreen />} />
                                <Route path="/" element={<UenaWebsiteScreen />} />
                                <Route path="/*" element={<UenaWebsiteScreen />} />
                            </>
                        }
                        {
                            process.env.REACT_APP_ENV === "order" &&
                            <>
                                <Route path="/create/order" element={<CreateOrderScreen />} />
                                <Route path="/create/order-cashier" element={<CashierOrderScreen />} />
                                <Route path="/gerobak" element={<GerobakOrderScreen />} />
                                <Route path="/service-area" element={<ServiceAreasMapScreen />} />
                                <Route path="/" element={<HomeScreen />} />
                                <Route path="/*" element={<HomeScreen />} />
                            </>
                        }
                        {
                            process.env.REACT_APP_ENV === "crm" &&
                            <>
                                <Route path="/adhoc-crm" element={<AdhocCrmScreen />} />
                                <Route path="/notification-web-form" element={<NotificationWebFormScreen />}/>
                                <Route path="/redirect-form" element={<WebRedirectFormScreen />} />
                                <Route path="/" element={<CrmHomeScreen />} />
                                <Route path="/*" element={<CrmHomeScreen />} />
                                <Route path="/r" element={<WebRedirectDisplayScreen />} />
                                <Route path="/notif" element={<NotificationWebDisplayScreen />} />
                            </>
                        }
                    </Routes>
                </div>
            </Router>
        );
    }
}