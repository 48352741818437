import ApiService from "../../commons/api/api_service";
import BaseResponse from "../../commons/api/base_response";
import { Endpoints } from "../../commons/api/endpoints";
import ViewModel from "../../commons/base/view_model";
import { ShowSnackbarModel } from "../../commons/scaffold/scaffold";
import StringUtils from "../../commons/utils/string_utils";
import WebRedirectFormRequest from "../models/web_redirect_form_request";
import WebRedirectFormResponse from "../models/web_redirect_form_response";
import WebRedirectFormState from "./web_redirect_form_state";

export default class WebRedirectFormViewModel extends ViewModel<WebRedirectFormState> {
    constructor() {
        super(new WebRedirectFormState());
    }

    onCustomeIdChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.emit((state) => state.customId = event.target.value);
    }

    onRedirectUrlChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.emit((state) => state.redirectUrl = event.target.value);
    }

    reset() {
        this.emit((state) => {
            state.customId = "";
            state.redirectUrl = "";
            state.errorSnackbar = undefined;
            state.webViewLink = undefined;
        })
    }

    submit() {
        if (!this.handleError()) {
            this.emit((state) => {
                state.isLoading = true;
                state.errorSnackbar = undefined;
            });

            ApiService.fetch<WebRedirectFormRequest, BaseResponse<WebRedirectFormResponse>>(Endpoints.redirectWebCreation, {
                parameters: {
                    customId: this.state.customId.length === 0 ? undefined : this.state.customId,
                    redirectUrl: this.state.redirectUrl
                },
                onSuccess: (response) => {
                    this.emit((state) => {
                        state.isLoading = false;
                        if (response.data) {
                            const host = process.env.REACT_APP_ENV === "production" ? "https://notif.uenafood.com" : window.location.origin;

                            state.webViewLink = `${host}/redirect?id=${response.data.id}`;
                        } else {
                            state.errorSnackbar = new ShowSnackbarModel({
                                message: response.message,
                                type: "error"
                            });
                        }
                    });
                },
                onFailure: (error) => {
                    this.emit((state) => {
                        state.isLoading = false;
                        state.errorSnackbar = new ShowSnackbarModel({
                            message: error,
                            type: "error"
                        });
                    });
                }
            });
        }
    }

    /// Return true if there's any error
    private handleError(): boolean {
        if (!StringUtils.checkIsValidUrl(this.state.redirectUrl)) {
            this.emit((state) => state.errorSnackbar = new ShowSnackbarModel({
                message: "Mohon periksa link URL pada Redirect Url. Format yang anda gunakan salah.",
                type: "error"
            }));

            return true;
        }

        return false;
    }
}