import { ShowSnackbarModel } from "../../commons/scaffold/scaffold";

interface ActionModel {
    label: string,
    deeplink: string
}

export default class NotificationWebFormState {
    isLoading: boolean = false;
    errorSnackbar?: ShowSnackbarModel;
    imageFile?: File;
    imageFileUrl?: string;
    paragraph: string = ""
    actions: ActionModel[] = [];
    webViewLink?: string;
}