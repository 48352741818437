import "./web_redirect_form.css"

import ViewController from "../../commons/base/view_controller";
import WebRedirectFormState from "../view_models/web_redirect_form_state";
import WebRedirectFormViewModel from "../view_models/web_redirect_form_view_model";
import Scaffold from "../../commons/scaffold/scaffold";
import SizedDiv from "../../commons/ui/sized_div";
import WebRedirectFormResultWidget from "../widgets/web_redirect_form_result_widget";
import WebRedirectFormFormWidget from "../widgets/web_redirect_form_form_widget";

export default class WebRedirectFormScreen extends ViewController<{}, WebRedirectFormState, WebRedirectFormViewModel> {
    constructor(props: {}) {
        super(props, new WebRedirectFormViewModel());
    }

    render() {
        return <Scaffold
            isShowLoading={this.vmState.isLoading}
            showSnackbar={this.vmState.errorSnackbar}
            loadingBackgroundType="semi_transparent"
            loadingType="uena_logo"
        >
            <div id="RedirectWebFormScreenContainer">
                <div id="RedirectWebFormScrollableContainer">
                    <p style={{
                        fontSize:32,
                        fontWeight:700,
                        textAlign:"center"
                    }}>Notification Web Form</p>
                    <SizedDiv height={42} />
                    {
                        this.vmState.webViewLink ?
                        <WebRedirectFormResultWidget vc={this} /> :
                        <WebRedirectFormFormWidget vc={this} />
                    }
                </div>
                {
                    !this.vmState.webViewLink && 
                    <div id="AdhocBottomSection">
                        <button 
                            className="FormButton"
                            onClick={() => this.viewModel.submit()}
                        >Generate Redirect Web</button>
                    </div>
                }
            </div>
        </Scaffold>;
    }
}