import Widget from "../../commons/base/widget";
import FormButton from "../../commons/ui/form_button";
import SizedDiv from "../../commons/ui/sized_div";
import NotificationWebFormScreen from "../screens/notification_web_form_screen";
import NotificationWebFormState from "../view_models/notification_web_form_state";
import NotificationWebFormViewModel from "../view_models/notification_web_form_view_model";


export default class NotificationWebFormResultWidget extends Widget<{}, NotificationWebFormState, NotificationWebFormViewModel, NotificationWebFormScreen> {
    render() {
        return <>
            <p style={{
                fontSize: 16,
                fontWeight: 700
            }}>Link Web View:</p>
            <a 
                href={this.vmState.webViewLink} 
                style={{
                    fontSize: 14,
                    color: "white",
                    textAlign: "start"
                }}
                target="_blank"
                rel="noreferrer" 
            >{this.vmState.webViewLink}</a>
            <SizedDiv height={24} />
            <FormButton 
                title="Buat Notification Web Lagi"
                action={() => this.viewModel.reset()}
            />
        </>;
    }
}