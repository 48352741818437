import FormButton from "../../commons/ui/form_button";
import SizedDiv from "../../commons/ui/sized_div";
import Widget from "../../commons/base/widget";
import NotificationWebFormScreen from "../screens/notification_web_form_screen";
import NotificationWebFormState from "../view_models/notification_web_form_state";
import NotificationWebFormViewModel from "../view_models/notification_web_form_view_model";

export default class NotificationWebFormFormWidget extends Widget<{}, NotificationWebFormState, NotificationWebFormViewModel, NotificationWebFormScreen> {
    render() {
        return <>
            {/* Image */}
            <p className="FormLabel">Gambar (Optional)</p>
            <input 
                type="file" 
                accept=".png,.jpg,.jpeg" 
                onChange={(event) => this.viewModel.onImageSelected(event)} 
            />
            {
                this.vmState.imageFileUrl &&
                <div id="AdhocCrmAttachedImageContainer">
                    <img src={this.vmState.imageFileUrl} alt="" />
                </div>
            }
            <SizedDiv height={12} />

            {/* Paragraf */}
            <p className="FormLabel">Paragraf</p>
            <textarea 
                className="FormTextInput"
                value={this.vmState.paragraph}
                onChange={(event) => this.viewModel.onParagraphChaged(event)}
                placeholder="Paragraf pada web view"
            />
            <p style={{fontSize: 13}}>INFO PENTING:<br/>Untuk membuat paragraf baru, cukup dengan <b>MENGGUNAKAN ENTER SEPERTI BIASA SAJA</b>.<br/><br/><i>Dan jangan gunakan double enter</i>, karena jarak antar paragraf akan sangat jauh. Kita sudah set jarak antar paragraf secara otomatis.</p>
            <SizedDiv height={12} />

            {/* Actions */}
            <p className="FormLabel">Actions</p>
            {
                this.vmState.actions.map((actionModel, index) => 
                    <div 
                        key={index}
                        style={{
                            display: "flex",
                            columnGap: 8
                        }}
                    >
                        <input 
                            className="FormTextInput"
                            value={actionModel.label}
                            onChange={(event) => this.viewModel.changeActionValue({
                                index: index,
                                label: event.target.value
                            })}
                            placeholder="Label"
                            style={{
                                flexShrink: 0,
                                width: 150
                            }}
                        />
                        <input 
                            className="FormTextInput"
                            value={actionModel.deeplink}
                            onChange={(event) => this.viewModel.changeActionValue({
                                index: index,
                                deeplink: event.target.value
                            })}
                            placeholder="Link Deeplink"
                            style={{
                                flexGrow: 1
                            }}
                        />
                        <button
                            onClick={() => this.viewModel.deleteAction({index: index})}
                            style={{
                                backgroundColor: "red",
                                borderRadius: 10,
                                color: "white",
                                padding: "6px 12px",
                                flexShrink: 0,
                                fontSize: 10,
                                fontWeight: "bold"
                            }}
                        >
                            ✕
                        </button>
                    </div>
                )
            }
            <FormButton 
                title="Tambahkan Action"
                action={() => this.viewModel.addAction()}
            />
        </>;
    }
}