import LabelAndIdModel from "../../commons/models/LabelIdModel";
import { ShowErrorViewModel, ShowSnackbarModel } from "../../commons/scaffold/scaffold";


export default class AdhocCrmState {
    isLoading: boolean = false;
    errorMessage: string = "";
    showErrorView?: ShowErrorViewModel = undefined;
    numbers: string[] = [];
    failedNumbers: string[] = [];
    imageFile?: File;
    imageFileUrl?: string;
    title: string = "";
    description: string = "";
    crmTypes: (LabelAndIdModel & { additionalInfoNeeded?: LabelAndIdModel })[] = [
        {
            id: "CART",
            label: "Cart",
        },
        {
            id: "PRODUCT_DETAIL",
            label: "Product Detail",
            additionalInfoNeeded: {
                id: "product_id",
                label: "ID Product"
            }
        },
        {
            id: "PRODUCT_LIST",
            label: "Product List",
        },
        {
            id: "WEB_VIEW",
            label: "Web View",
            additionalInfoNeeded: {
                id: "url_link",
                label: "Link Web View"
            }
        },
    ];
    selectedCrmTypeIndex?: number;
    additionalInfo?: string;

    // Snackbar
    showSnackbarModel?: ShowSnackbarModel;

    get selectedCrmTypeId(): string | undefined {
        return this.selectedCrmTypeIndex !== undefined ? this.crmTypes[this.selectedCrmTypeIndex ?? 0].id : undefined;
    }

    get selectedCrmAdditionalInfoNeeded(): LabelAndIdModel | undefined {
        return this.selectedCrmTypeIndex !== undefined ? this.crmTypes[this.selectedCrmTypeIndex ?? 0].additionalInfoNeeded : undefined;
    }

    reset() {
        this.isLoading = false;
        this.errorMessage = "";
        this.numbers = [];
        this.failedNumbers = [];
        this.imageFile = undefined;
        this.imageFileUrl = undefined;
        this.title = "";
        this.description = "";
        this.selectedCrmTypeIndex = undefined;
        this.additionalInfo = undefined;
        this.showSnackbarModel = undefined;
    }
}