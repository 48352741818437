import Widget from "../../commons/base/widget";
import SizedDiv from "../../commons/ui/sized_div";
import WebRedirectFormScreen from "../screens/web_redirect_form_screen";
import WebRedirectFormState from "../view_models/web_redirect_form_state";
import WebRedirectFormViewModel from "../view_models/web_redirect_form_view_model";

export default class WebRedirectFormFormWidget extends Widget<{}, WebRedirectFormState, WebRedirectFormViewModel, WebRedirectFormScreen> {
    render() {
        return <>
            <p className="FormLabel">Custom ID (Optional)</p>
            <input 
                className="FormTextInput"
                value={this.vmState.customId}
                onChange={(event) => this.viewModel.onCustomeIdChanged(event)}
                placeholder="Custom ID"
            />
            <p style={{fontSize: 13}}>Jika dibiarkan kosong, sistem yang akan generate ID.</p>
            <SizedDiv height={12} />

            <p className="FormLabel">Redirect URL</p>
            <input 
                className="FormTextInput"
                value={this.vmState.redirectUrl}
                onChange={(event) => this.viewModel.onRedirectUrlChanged(event)}
                placeholder="Redirect URL"
            />
        </>;
    }
}