import ApiService from "../../commons/api/api_service";
import { Endpoints } from "../../commons/api/endpoints";
import ViewModel from "../../commons/base/view_model";
import AdhocCrmState from "./adhoc_crm_state";
import AdhocCrmRequest from "../models/adhoc_crm_request";
import AdhocCrmResponse from "../models/adhoc_crm_response";
import { ShowSnackbarModel } from "../../commons/scaffold/scaffold";
import BaseResponse from "../../commons/api/base_response";

const NUMBER_OF_CONTACT_WILL_BE_SENT_PER_REQUEST = 100;
const LIMIT_TO_A_TIME_GAP_FOR_NEXT_REQUEST = 4;
const TIME_GAP_IN_MILIS = 4000;

export default class AdhocCrmViewModel extends ViewModel<AdhocCrmState> {
    sendBatchCounting = 0;

    private get isSubmitButtonEnabled(): boolean {
        return this.state.numbers.length > 0 &&
            this.state.title.length > 0 &&
            this.state.description.length > 0 &&
            ((this.state.selectedCrmAdditionalInfoNeeded !== undefined && this.state.additionalInfo !== undefined) || this.state.selectedCrmAdditionalInfoNeeded === undefined);
    }

    constructor() {
        super(new AdhocCrmState())
    }

    onFileSelected(files: FileList | null) {
        const file = files?.item(0);
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
            const text = (event.target?.result as string).replaceAll("\r", "");
            let numbers: string[] = [];

            text.split("\n").forEach((value) => {
                if (value.length > 0 && !isNaN(+value)) {
                    numbers.push(value.replaceAll("\r", ""));
                }
            });

            console.log(text);

            this.emit((state) => {
                state.numbers = numbers;
            });
        }

        if (file) {
            fileReader.readAsText(file);
        }
    }

    onImageSelected(event: React.ChangeEvent<HTMLInputElement>) {
        this.emit((state) => state.imageFile = event.target.files?.item(0) ?? undefined);

        if (this.state.imageFile) {
            const fileReader = new FileReader();

            fileReader.onload = (event) => {
                const { result } = event.target as FileReader;

                if (result) {
                    this.emit((state) => state.imageFileUrl = result as string);
                }
            }

            fileReader.readAsDataURL(this.state.imageFile);
        } else {
            this.emit((state) => state.imageFileUrl = undefined);
        }
    }

    onTitleChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.emit((state) => state.title = event.target.value);
    }

    onDescriptionChanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.emit((state) => state.description = event.target.value);
    }

    onCrmSelected(index: number) {
        this.emit((state) => {
            if (index !== state.selectedCrmTypeIndex) {
                state.selectedCrmTypeIndex = index;
                state.additionalInfo = undefined;
            } else {
                state.selectedCrmTypeIndex = undefined;
            }
        });
    }

    onAdditionalInfoChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.emit((state) => state.additionalInfo = event.target.value);
    }

    submit() {
        if (this.isSubmitButtonEnabled) {
            this.emit((state) => {
                state.isLoading = true;
                state.showSnackbarModel = undefined;
            });

            const imageFile = this.state.imageFile;

            if (imageFile) {
                ApiService.uploadFile(Endpoints.uploadImage, {
                    file: imageFile,
                    bucketName: "kokilogy-storage",
                    folderName: "crm_images",
                    onSuccess: (response) => {
                        if (response.url) {
                            this.submitCrmForm(response.url);
                        } else {
                            this.emit((state) => {
                                state.showSnackbarModel = new ShowSnackbarModel({
                                    message: response.message,
                                    type: "error"
                                });
                            });
                        }
                    },
                    onFailure: (error) => {
                        console.log(error);
                        this.emit((state) => {
                            state.showSnackbarModel = new ShowSnackbarModel({
                                message: error,
                                type: "error"
                            });
                        });
                    }
                });
            } else {
                this.submitCrmForm();
            }
        }
    }

    retrySubmitCrmForm(imageUrl?: string) {
        this.emit((state) => {
            state.numbers = state.failedNumbers;
            state.failedNumbers = [];
        });

        this.submitCrmForm(imageUrl);
    }

    private submitCrmForm(imageUrl?: string, startIndex?: number, errorMessage?: string) {
        const startIndexOfContacts = startIndex ?? 0;
        const prevErrorMessage = errorMessage ?? "";

        this.emit((state) => {
            state.isLoading = true;
            state.showErrorView = undefined;
        });

        if (startIndexOfContacts >= this.state.numbers.length) {
            this.emit((state) => {
                if (state.errorMessage.length > 0 || state.failedNumbers.length > 0) {
                    state.showErrorView = {
                        message: `GAGAL MENGIRIM UNTUK BEBERAPA NOMOR ATAU SEMUA NOMOR:\n${errorMessage}.\nSilahkan coba lagi untuk mengirim ke nomor-nomor yang gagal.`,
                        action: () => {
                            if (this.sendBatchCounting !== 0 && this.sendBatchCounting % LIMIT_TO_A_TIME_GAP_FOR_NEXT_REQUEST === 0) {
                                setTimeout(() => this.retrySubmitCrmForm(imageUrl), TIME_GAP_IN_MILIS);
                            } else {
                                this.retrySubmitCrmForm(imageUrl);
                            }
                        }
                    };
                } else {
                    state.showSnackbarModel = new ShowSnackbarModel({
                        message: "Berhasil menambahkan Adhoc CRM",
                        type: "info"
                    });
                }
            });

            this.emit((state) => state.reset());
            this.sendBatchCounting = 0;

            return;
        }

        const lastIndexOfContacts = startIndexOfContacts + NUMBER_OF_CONTACT_WILL_BE_SENT_PER_REQUEST > this.state.numbers.length
            ? this.state.numbers.length
            : startIndexOfContacts + NUMBER_OF_CONTACT_WILL_BE_SENT_PER_REQUEST;

        const contactsForThisRequest = this.state.numbers.slice(startIndexOfContacts, lastIndexOfContacts);

        ApiService.fetch<AdhocCrmRequest, BaseResponse<AdhocCrmResponse>>(Endpoints.pushCrm, {
            parameters: {
                phoneNumbers: contactsForThisRequest,
                title: this.state.title,
                description: this.state.description,
                type: this.state.selectedCrmTypeId,
                additionalValue: this.state.additionalInfo,
                imageUrl: imageUrl
            },
            onSuccess: (response) => {
                this.sendBatchCounting += 1;

                this.emit((state) => {
                    state.isLoading = false;

                    if (response.data) {
                        this.submitCrmForm(imageUrl, lastIndexOfContacts, prevErrorMessage);
                    } else {
                        state.failedNumbers.push(...contactsForThisRequest);
                        this.submitCrmForm(imageUrl, lastIndexOfContacts, prevErrorMessage + `\n${contactsForThisRequest} -- ${response.message}`);
                    }
                });
            },
            onFailure: (error) => {
                this.emit((state) => {
                    state.isLoading = false;
                    state.failedNumbers.push(...contactsForThisRequest);
                    this.submitCrmForm(imageUrl, lastIndexOfContacts, prevErrorMessage + `\n${contactsForThisRequest} -- ${error}`);
                });
            }
        });
    }
}