import "./adhoc_crm.css";

import Scaffold from "../commons/scaffold/scaffold";
import SizedDiv from "../commons/ui/sized_div";
import ViewController from "../commons/base/view_controller";
import AdhocCrmState from "./view_models/adhoc_crm_state";
import AdhocCrmViewModel from "./view_models/adhoc_crm_view_model";
import RadioFormWidget from "../commons/ui/radio_form/radio_form_widget";

export default class AdhocCrmScreen extends ViewController<{}, AdhocCrmState, AdhocCrmViewModel> {
    constructor(props: {}) {
        super(props, new AdhocCrmViewModel());
    }

    render() {
        return <Scaffold
            isShowLoading={this.vmState.isLoading}
            loadingBackgroundType="semi_transparent"
            showSnackbar={this.vmState.showSnackbarModel}
            showErrorView={this.vmState.showErrorView}
        >
            <div id="AdhocCrmScreenContainer">
                <div id="AdhocCrmScreenMainContentContainer">
                    <p style={{
                        fontSize:32,
                        fontWeight:700,
                        textAlign:"center"
                    }}>Adhoc CRM (3)</p>
                    <SizedDiv height={42} />

                    {/* CSV */}
                    <p className="FormLabel">Daftar Nomor Penerima [.csv]</p>
                    <input 
                        type="file" 
                        accept=".csv" 
                        onChange={(event) => this.viewModel.onFileSelected(event.target.files)} 
                    />  
                    <p style={{ fontSize:12 }}>Terdapat {this.vmState.numbers.length} nomor</p>
                    <SizedDiv height={12} />

                    {/* Judul */}
                    <p className="FormLabel">Judul dan Badan Pesan</p>
                    <p style={{ fontSize:12 }}
                    >Untuk memasukkan nama, gunakan "<b>{"{{name}}"}</b>"</p>
                    <input 
                        value={this.vmState.title}
                        onChange={(event) => this.viewModel.onTitleChanged(event)}
                        placeholder="Masukkan judul pesan disini"
                    />
                    <textarea 
                        value={this.vmState.description}
                        onChange={(event) => this.viewModel.onDescriptionChanged(event)}
                        placeholder="Masukkan badan pesan disini"
                    />
                    <SizedDiv height={12} />

                    {/* Image */}
                    <p className="FormLabel">Gambar Sisipan (Optional)</p>
                    <input 
                        type="file" 
                        accept=".png,.jpg,.jpeg" 
                        onChange={(event) => this.viewModel.onImageSelected(event)} 
                    />
                    {
                        this.vmState.imageFileUrl &&
                        <div id="AdhocCrmAttachedImageContainer">
                            <img src={this.vmState.imageFileUrl} alt="" />
                        </div>
                    }
                    <SizedDiv height={12} />

                    {/* Tipe CRM */}
                    <p className="FormLabel">Tipe CRM (Optional)</p>
                    <RadioFormWidget
                        items={this.vmState.crmTypes}
                        selectedId={this.vmState.selectedCrmTypeId}
                        onSelectItem={(_, index) => this.viewModel.onCrmSelected(index)}
                    />
                    <SizedDiv height={12} />

                    {/* Additional Info */}
                    {
                        this.vmState.selectedCrmAdditionalInfoNeeded &&
                        <>
                            <p className="FormLabel">{this.vmState.selectedCrmAdditionalInfoNeeded.label}</p>
                            <input 
                                key={this.vmState.selectedCrmAdditionalInfoNeeded.label}
                                value={this.vmState.additionalInfo}
                                onChange={(event) => this.viewModel.onAdditionalInfoChanged(event)}
                                placeholder={`Masukkan ${this.vmState.selectedCrmAdditionalInfoNeeded.label} disini`}
                            />
                        </>
                    }
                </div>
                <div id="AdhocBottomSection">
                    <button 
                        className="FormButton"
                        onClick={() => this.viewModel.submit()}
                    >Kirimkan CRM</button>
                </div>
            </div>
        </Scaffold>;
    }
}