import React from "react";
import ViewModel from "../../commons/base/view_model";
import NotificationWebFormState from "./notification_web_form_state";
import ApiService from "../../commons/api/api_service";
import { Endpoints } from "../../commons/api/endpoints";
import NotificationWebFormRequest from "../models/notification_web_form_request";
import NotificationWebFormResponse from "../models/notification_web_form_response";
import { ShowSnackbarModel } from "../../commons/scaffold/scaffold";
import StringUtils from "../../commons/utils/string_utils";

export default class NotificationWebFormViewModel extends ViewModel<NotificationWebFormState> {
    constructor() {
        super(new NotificationWebFormState());
    }

    onImageSelected(event: React.ChangeEvent<HTMLInputElement>) {
        this.emit((state) => state.imageFile = event.target.files?.item(0) ?? undefined);

        if (this.state.imageFile) {
            const fileReader = new FileReader();

            fileReader.onload = (event) => {
                const { result } = event.target as FileReader;

                if (result) {
                    this.emit((state) => state.imageFileUrl = result as string);
                }
            }

            fileReader.readAsDataURL(this.state.imageFile);
        } else {
            this.emit((state) => state.imageFileUrl = undefined);
        }
    }

    onParagraphChaged(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.emit((state) => {
            state.paragraph = event.target.value;
        });
    }

    addAction() {
        this.emit((state) => {
            state.actions.push({
                label: "",
                deeplink: ""
            });
        });
    }

    deleteAction(args: { index: number }) {
        this.emit((state) => {
            state.actions.splice(args.index, 1);
        });
    }

    changeActionValue(args: { index: number, label?: string, deeplink?: string }) {
        this.emit((state) => {
            state.actions[args.index].label = args.label ?? state.actions[args.index].label;
            state.actions[args.index].deeplink = args.deeplink ?? state.actions[args.index].deeplink;
        });
    }

    submit() {
        if (!this.handleError()) {
            this.emit((state) => {
                state.isLoading = true;
                state.errorSnackbar = undefined;
            });

            const imageFile = this.state.imageFile;

            if (imageFile) {
                ApiService.uploadFile(Endpoints.uploadImage, {
                    file: imageFile,
                    bucketName: "kokilogy-storage",
                    folderName: "web_view_contents",
                    onSuccess: (response) => {
                        if (response.url) {
                            this.submitForm(response.url);
                        } else {
                            this.emit((state) => {
                                state.isLoading = false;
                                state.errorSnackbar = new ShowSnackbarModel({
                                    message: response.message,
                                    type: "error"
                                });
                            })
                        }
                    }, onFailure: (error) => {
                        console.log(error);
                        this.emit((state) => {
                            state.isLoading = false;
                            state.errorSnackbar = new ShowSnackbarModel({
                                message: error,
                                type: "error"
                            });
                        });
                    }
                });
            } else {
                this.submitForm();
            }
        }
    }

    reset() {
        this.emit((state) => {
            state.actions = [];
            state.imageFile = undefined;
            state.imageFileUrl = undefined;
            state.paragraph = "";
            state.webViewLink = undefined;
        });
    }

    private submitForm(imageUrl?: string) {
        ApiService.fetch<NotificationWebFormRequest, NotificationWebFormResponse>(Endpoints.notificationWebViewCreation, {
            parameters: {
                message: this.state.paragraph,
                imageUrl: imageUrl,
                actions: this.state.actions.map((actionModel) => {
                    return { ...actionModel, type: "" };
                })
            },
            onSuccess: (response) => {
                this.emit((state) => {
                    const host = process.env.REACT_APP_ENV === "production" ? "https://uenafood.com" : window.location.origin;
                    state.webViewLink = `${host}/notif?id=${response.id}`;
                    state.isLoading = false;
                });
            },
            onFailure: (error) => {
                this.emit((state) => {
                    state.isLoading = false;
                    state.errorSnackbar = new ShowSnackbarModel({
                        message: error,
                        type: "error"
                    });
                });
            }
        });
    }

    /// Return true if there's any error
    private handleError(): boolean {
        let errorMessage = "";

        if (this.state.paragraph.length === 0) {
            errorMessage += "paragraf tidak boleh kosong, ";
        }

        for (let index = 0; index < this.state.actions.length; index++) {
            const actionModel = this.state.actions[index];

            if (actionModel.label.length === 0) {
                errorMessage += `label pada action yang ke ${index + 1} tidak boleh kosong, `;
            }

            if (actionModel.deeplink.length === 0) {
                errorMessage += `deeplink pada action yang ke ${index + 1} tidak boleh kosong, `;
            } else if (!StringUtils.checkIsValidUrl(actionModel.deeplink)) {
                errorMessage += `format deeplink pada action yang ke ${index + 1} salah, `;
            }

            console.log(StringUtils.checkIsValidUrl(actionModel.deeplink));
        }

        if (errorMessage.length > 0) {
            errorMessage = `Coba cek beberapa kesalahan berikut: ${errorMessage}`;

            this.emit((state) => state.errorSnackbar = new ShowSnackbarModel({
                message: errorMessage,
                type: "error"
            }));

            return true;
        }

        return false;
    }
}